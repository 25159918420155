import Layout from '@/modules/layout/components/layout.vue';

const NewsPage = () => import('@/modules/news/components/news-page.vue');
const HomePage = () => import('@/modules/home/components/home-page.vue');
const AddStudentPage = () => import('@/modules/departamnets/components/add-student-page.vue');
const ImportStudentsPage = () => import('@/modules/departamnets/components/import-students-page.vue');
const ManagementPage = () => import('@/modules/departamnets/components/management-page.vue');
const DepartamentPage = () => import('@/modules/departamnets/pages/departament.vue');
const GroupPage = () => import('@/modules/departamnets/pages/group.vue');
const StudentPage = () => import('@/modules/departamnets/pages/students/student.vue');
/*  const TestsResults = () => import('@/modules/departamnets/pages/tests/testsResults.vue');
const TestResults = () => import('@/modules/departamnets/pages/tests/testResults.vue');
const TestStudentResults = () =>
import('@/modules/departamnets/pages/tests/testStudentResults.vue');
const PollsResults = () => import('@/modules/departamnets/pages/polls/pollsResults.vue');
const PollResults = () => import('@/modules/departamnets/pages/polls/pollResults.vue');
const PollsPage = () => import('@/modules/polls/components/poll-page.vue'); */
const StudentsResults = () => import('@/modules/departamnets/pages/students/students.vue');
const StudentResults = () => import('@/modules/departamnets/pages/students/studentResults.vue');
const MentorsPage = () => import('@/modules/mentors/components/mentors-page.vue');
const MentorDetailsPage = () => import('@/modules/mentors/components/mentor-details.vue');
/*  const TestsPage = () => import('@/modules/tests/components/test-page.vue');
const TagsPage = () => import('@/modules/tags/components/tags-page.vue'); */
const CategoriesPage = () => import('@/modules/tags/components/categories-page.vue');
const AiCards = () => import('../ai-cards/ai-cards.vue');
const ChatPage = () => import('@/modules/chat/components/chat-page.vue');
const FilesAi = () => import('@/modules/file-ai/components/files-ai-page.vue');
const FileAi = () => import('@/modules/file-ai/components/file/file-ai-page.vue');
const FileAiLearningCards = () => import('@/modules/file-ai/components/file/file-ai-learning-cards.vue');
const FileAiView = () => import('@/modules/file-ai/components/file/file-ai-view.vue');
const FileAiEvaluation = () => import('@/modules/file-ai/components/file/file-ai-evaluation.vue');
const AffiliationPage = () => import('@/modules/affiliation/components/affiliation-page.vue');
/*
const CategoriesLearningPage = () =>
 import('@/modules/learning-sets/components/categories-learning-page.vue');
const LearningSetsPage = () => import('@/modules/learning-sets/components/learning-sets-page.vue');
*/

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'home',
        path: '',
        component: HomePage,
        exact: true,
      },
      {
        name: 'news',
        path: '/news',
        component: NewsPage,
        exact: true,
      },
      {
        name: 'management',
        path: '/management',
        component: ManagementPage,
        exact: true,
        children: [
          {
            name: 'management_department',
            path: '/management/department/:parent_id',
            component: DepartamentPage,
            exact: true,
          },
          {
            name: 'management_group',
            path: '/management/group/:parent_id',
            component: GroupPage,
            exact: true,
          },
          {
            name: 'management_student',
            path: '/management/student/:parent_id',
            component: StudentPage,
            exact: true,
            children: [
              {
                name: 'management_students_results',
                path: '/management/student/students-results/:parent_id',
                component: StudentsResults,
                exact: true,
              },
              {
                name: 'management_student_results',
                path: '/management/student/student-results/:parent_id/:student_id/:selector',
                component: StudentResults,
                exact: true,
              },
              /*  {
                 name: 'management_tests_results',
                 path: '/management/student/tests-results/:parent_id',
                 component: TestsResults,
                 exact: true,
               },
               {
                 name: 'management_test_results',
                 path: '/management/student/test-results/:parent_id/:test_id',
                 component: TestResults,
                 exact: true,
               },
               {
                 name: 'management_test_student_results',
                 path: '/management/student/test-student-results/:parent_id/:test_id/:student_id',
                 component: TestStudentResults,
                 exact: true,
               },
               {
                 name: 'management_polls_results',
                 path: '/management/student/polls-results/:parent_id',
                 component: PollsResults,
                 exact: true,
               },
               {
                 name: 'management_poll_results',
                 path: '/management/student/poll-results/:parent_id/:poll_id',
                 component: PollResults,
                 exact: true,
               },   */
            ],
          },
        ],
      },
      {
        name: 'student_add',
        path: '/student/add/:parent_id/:id',
        component: AddStudentPage,
        exact: true,
      },
      {
        name: 'students_import',
        path: '/student/import/:parent_id',
        component: ImportStudentsPage,
        exact: true,
      },
      {
        name: 'mentors',
        path: '/mentors',
        component: MentorsPage,
        exact: true,
      },
      {
        name: 'mentor_details',
        path: '/mentor-details/:id',
        component: MentorDetailsPage,
        exact: true,
      },
      /*   {
        name: 'tests',
        path: '/tests',
        component: TestsPage,
        exact: true,
      },
      {
        name: 'polls',
        path: '/polls',
        component: PollsPage,
        exact: true,
      },    */
      {
        name: 'tags_categories',
        path: 'categories',
        component: CategoriesPage,
        exact: true,
      },
      {
        name: 'ai_cards',
        path: 'ai-cards',
        component: AiCards,
        exact: true,
      },
      {
        name: 'content',
        path: 'content',
        component: ChatPage,
        exact: true,
      },
      {
        name: 'file_ai',
        path: 'file-ai',
        component: FilesAi,
        exact: true,
      },
      {
        name: 'process_file_ai',
        path: 'file-ai/:id',
        component: FileAi,
        exact: true,
        children: [
          {
            name: 'process_file_ai_learning_cards',
            path: '/file-ai/:id/learning-cards',
            component: FileAiLearningCards,
            exact: true,
          },
          {
            name: 'process_file_ai_evaluation',
            path: '/file-ai/:id/evaluation',
            component: FileAiEvaluation,
            exact: true,
          },
          {
            name: 'process_file_ai_view',
            path: '/file-ai/:id/view',
            component: FileAiView,
            exact: true,
          },
        ],
      },
      {
        name: 'affiliation',
        path: 'affiliation',
        component: AffiliationPage,
        exact: true,
      },
      /*    {
        name: 'tags',
        path: '/tags/:category_id',
        component: TagsPage,
        exact: true,
      },
       {
         name: 'learning_sets_categories',
         path: 'learning-sets',
         component: CategoriesLearningPage,
         exact: true,
       },
      {
         name: 'learning_sets',
         path: '/learning-sets/:category_id',
         component: LearningSetsPage,
         exact: true,
       },     */
    ],
  },
];
